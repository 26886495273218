#Preloader {
	position: absolute;
	display: grid;
	height: 100%;
	width: 100%;
	margin: 0;
	place-items: center center;
	background-color: #000000;
	z-index: 10;
	pointer-events: none;
	background: url(/assets/images/preloader-background.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.animated_logo {
		opacity: 0;
		margin-bottom: 60px;
		width: 60%;

		path {
			fill: white;
		}

		path.leftAntler {
			fill: #1574ff;
		}

		path.rightAntler {
			fill: #1574ff;
		}
	}

	.centerContent {
		z-index: 20;

		max-width: 400px;
		text-align: center;
		//margin-bottom: 150px;
		margin: auto;

		.innerContent {
			margin-right: 20px;
			margin-left: 20px;

			.preloadFont {
				font-family: 'Londrina Solid', cursive;
				visibility: hidden;
			}

			.logo {
				margin-top: 20px;
				opacity: 0;
				margin-bottom: 60px;
				width: 100%;
				height: 100%;
				margin-right: 20px;
				object-fit: scale-down;
			}

			h1 {
				opacity: 0;
				font-size: 50px;
				font-weight: 500;
			}

			h2 {
				opacity: 0;
				color: #ffffff;
				font-size: 22px;
				font-weight: 500;
			}

			.highlight {
				color: #aaaaaa;
			}

			.note {
				font-size: 20px;
				opacity: 0;
				margin-top: -20px;
				margin-bottom: 34px;
			}

			.button {
				pointer-events: all;
				opacity: 0;
				//	position: relative;
				width: 105px;
				height: 40px;
				display: block;
				cursor: pointer;
				margin: 0 auto;

				svg {
					position: absolute;
					top: 0;
					left: 0;
				}

				p {
					position: absolute;
					font-size: 18px;
					z-index: 2;
					top: 9px;
					left: 15px;
					margin-bottom: 0;
				}
			}

			.button:hover {
				svg {
					opacity: 0.8;
				}
			}

			.divider {
				font-size: 60px;
				line-height: 10px;
				margin-top: 0px;
				margin-bottom: 30px;
			}

			.loader {
				position: relative;
				top: 95px;
				opacity: 0;
				left: 0px;

				width: 100%;

				.loaderForeground {
					height: 1px;
					width: 0%;
					background-color: #ffffff;
				}

				.loaderBackground {
					height: 1px;
					width: 100%;
					background-color: #333333;
				}
			}
		}
	}

	h3 {
		opacity: 0;

		text-align: center;
		position: absolute;
		font-size: 22px;
		font-weight: 500;
		bottom: 32px;
		width: 100%;
		text-align: center;

		.headphonesImage {
			img {
				margin-bottom: 11px;
			}
		}

		.rememberSoundText {
			font-size: 16px;
			opacity: 0.4;
		}

		@media only screen and (max-height: 640px) {
			display: none;
		}
	}

	@media only screen and (max-height: 540px) {
		.animated_logo {
			width: 30%;
			padding-top: 40px;
		}
	}

	@media (min-width: 768px) {
		.animated_logo {
			width: 70%;
		}
	}

	@media (min-width: 1024px) {
		.animated_logo {
			width: 100%;
		}
	}
}
