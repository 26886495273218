#IntroScreen {
	pointer-events: none;
	display: none;
	//opacity: 0;
	position: absolute;
	z-index: 12;
	width: 100%;
	height: 100%;

	//background-color: rgba(0, 0, 0, 0.0);

	.images {
		position: absolute;
		max-width: calc(1376px / 1.5);
		max-height: calc(800px / 1.5);
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		img {
			display: none;
			position: absolute;
			opacity: 0;
			width: 100%;
			height: 100%;
			object-fit: scale-down;
			//	filter: grayscale(100%) brightness(200%);
		}
	}

	.centerContent {
		position: absolute;
		bottom: 14%;
		//max-width: 800px;
		width: 100%;
		font-size: 16px;
		text-align: center;
		margin: 0 auto;

		@media only screen and (max-height: 700px) {
			font-size: 16px;
		}

		.line1 {
			opacity: 0;
		}

		.line2 {
			opacity: 0;
		}

	}

	@media (min-width: 660px) {
		.centerContent {
			font-size: 27px;
		}
	}

	.skip {
		position: absolute;
		bottom: 50px;
		text-decoration: underline;
		pointer-events: all;
		left: calc(50% - 15px);
		cursor: pointer;
		font-size: 20px;

		@media only screen and (max-height: 700px) {
			font-size: 16px;
			bottom: 30px;
		}
	}
}
