#LevelScreen {
	pointer-events: none;
	display: none;
	opacity: 0;
	position: absolute;
	z-index: 12;
	width: 100%;
	height: 100%;
	//background-color: rgba(0, 0, 0, 0.0);

	.centerImage {
		position: absolute;

		top: calc(50% - 1020px / 2);
		left: calc(50% - 1020px / 2);

		img {
			width: 1020px;
			height: 1020px;
			object-fit: scale-down;
		}
	}

	.centerContent {
		position: absolute;

		height: 400px;
		margin: 0;

		top: calc(100% / 2 - 0px);

		font-size: 27px;
		text-align: center;

		.level {
			width: 100vw;
			font-size: 30px;
			margin-top: -80px;
			margin-bottom: 5px;
			color: #ffffff;
			text-align: center;

			.name {
				color: #ffffff;
			}

			//	opacity: 0.7;
		}

		.wayToGo {
			display: block;
			color: #ffffff;
			font-size: 24px;
			margin-bottom: 30px;
			font-weight: normal;
			font-style: normal;
		}

		.nextLevel {
			display: block;
			color: #ffffff;
			font-size: 30px;
			margin-bottom: 30px;
			font-weight: normal;
			font-style: normal;
			display: none;
		}
	}

	@media (min-width: 768px) {
		.centerContent {
			.level {
				font-size: 40px;
			}

			.wayToGo {
				font-size: 70px;
			}
		}
	}
}
