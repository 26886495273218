#LevelsCompleted {
	position: absolute;
	top: 0px;
	width: 100%;
	z-index: 100;
	font-size: 18px;
	display: none;
	background-color: rgba(0, 0, 0, 0.95); //rgba(7, 7, 66, 0.9);
	height: 100%;
	color: #cccccc;
	overflow-y: auto;

	.container {
		width: 100%;
		height: 100%;
		padding-left: 40px;
		padding-right: 40px;
		position: relative;

		.logo {
			position: absolute;
			left: 50%;
			transform: translate3d(-50%, 92px, 0px);
		}
	}

	.textContent {
		pointer-events: all;
		padding-top: 240px;
		max-width: 1140px;
		margin: auto;
		position: relative;

		user-select: text;
		-webkit-user-select: text;
		text-align: center;
		//margin-top: 50px;

		-moz-user-select: text;
		-khtml-user-select: text;
		-webkit-user-select: text;
		-ms-user-select: text;
		user-select: text;

		a {
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
			color: #ffffff;
			text-decoration: underline;
		}

		p {
			color: #ffffff;
			font-weight: normal;
			font-size: 16px;
			margin-bottom: 40px;
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
		}

		.button {
			position: relative;
			width: 105px;
			height: 40px;
			display: block;
			cursor: pointer;
			margin: 0 auto;

			svg {
				position: absolute;
				top: 0;
				left: 0;
			}

			p {
				position: absolute;
				font-size: 18px;
				z-index: 2;
				top: 9px;
				left: 15px;
				margin-bottom: 0;
			}
		}

		.button:hover {
			svg {
				opacity: 0.8;
			}
		}

		.yourTimeTitle {
			font-size: 20px;
			margin-bottom: 10px;
		}

		.yourTimeDisplay {
			margin-bottom: 30px;
			font-size: 50px;
		}

		//.playAgain {
		//
		//	font-weight: 500;
		//	font-size: 26px;
		//	margin-bottom: 50px;
		//	-moz-user-select: text;
		//	-khtml-user-select: text;
		//	-webkit-user-select: text;
		//	-ms-user-select: text;
		//	user-select: text;
		//	width: auto;
		//	text-decoration: underline;
		//	cursor: pointer;
		//	pointer-events: all;
		//}
	}

	.social {
		bottom: 30px;
		position: absolute;
		font-size: 18px;
		margin-bottom: 0;
		width: calc(100% - 80px);
		text-align: center;

		.title {
			margin-bottom: 12px;
		}

		a {
			font-size: 16px;
			text-decoration: underline;
			color: white;
		}

		.fb {
			margin-right: 20px;
		}
	}

	@media (max-height: 600px) {
		.social {
			padding-top: 40px;
			padding-bottom: 30px;
			bottom: unset;
		}
	}

	@media (min-width: 660px) {
		.textContent {
			p {
				font-size: 30px;
				margin-bottom: 50px;
			}

			.sub {
				font-size: 18px;
			}
		}
	}

	@media (min-width: 1024px) {
		.textContent {
			p {
				font-size: 70px;
				margin-bottom: 40px;
			}
		}
	}
}
