#TopContent {
	position: absolute;
	width: 100%;
	margin: 0;
	place-items: center center;
	z-index: 10;
	visibility: hidden;
	text-align: center;
	font-weight: 400;
	z-index: 300;


	.centerContent {
		max-width: 400px;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
		letter-spacing: 2px;

		@media (max-width: 723px) {
			margin-top: 20px;
		}

		.time {
			opacity: 1;
			color: #ffffff;
			font-size: 21px;

		}
	}


	.leftContent {
		position: absolute;
		top: 40px;
		left: 40px;
		display: flex;
		margin: 0 auto;

		@media (max-width: 723px) {
			top: 20px;
		}

		font-weight: 500;
	//	display: none;

		.aboutButton {
			font-size: 17px;
			pointer-events: all;
			cursor: pointer;
		}
	}
}
