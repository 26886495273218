#About {
	position: absolute;
	top: 0px;
	width: 100%;
	z-index: 200;
	font-size: 18px;
	//pointer-events: none;
	display: none;
	background-color: rgba(0, 0, 0, 0.95); //rgba(7, 7, 66, 0.9);
	height: 100%;
	color: #cccccc;
	overflow-y: auto;

	.container {
		width: 100%;
		height: 100%;
		padding-left: 40px;
		padding-right: 40px;
		position: relative;

		.logo {
			position: absolute;
			width: 194px;
			height: 167px;
			left: 50%;
			transform: translate3d(-50%, 50px, 0px);
		}
	}

	.textContent {
		pointer-events: all;
		padding-top: 320px;
		max-width: 1140px;
		margin: auto;
		position: relative;

		user-select: text;
		-webkit-user-select: text;
		text-align: center;
		//margin-top: 50px;

		-moz-user-select: text;
		-khtml-user-select: text;
		-webkit-user-select: text;
		-ms-user-select: text;
		user-select: text;

		a {
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
			color: #ffffff;
			text-decoration: underline;
		}

		p {
			color: #ffffff;
			font-weight: normal;
			font-size: 16px;
			margin-bottom: 40px;
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
		}

		.sub {
			font-size: 14px;
			opacity: 0.5;
			margin: 0 auto;
			max-width: 620px;
			text-align: center;
			position: relative;
			margin-bottom: 40px;
		}

		.credits {

			font-size: 18px;
			margin-bottom: 0;
			width: calc(100% - 0px);
			text-align: center;
			margin-bottom: 60px;

			a {
				color: white;
				text-decoration: underline;
			}
		}
	}



	@media (min-width: 660px) {
		.textContent {
			p {
				font-size: 30px;
				margin-bottom: 50px;
			}

			.sub {
				font-size: 18px;
			}
		}
	}

	@media (min-width: 1024px) {
		.textContent {
			p {
				font-size: 36px;
				margin-bottom: 120px;
			}
		}
	}
}
