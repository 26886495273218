#AllowWebcamScreen {
	pointer-events: none;
	display: none;
	opacity: 0;
	position: relative;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	//background-color: rgba(0, 0, 0, 0.0);
	text-align: center;
	//background-color: #000;

	.centerContent {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.header {
			font-size: 64px;
			width: 100%;
		}

		.subheader {
			margin-top: 10px;
		}

		.videoArea {
			margin: 0 auto;
			margin-bottom: 18px;
			margin-top: 27px;



			video {
				background-color: #000000;
				//	-webkit-filter: grayscale(100%) brightness(70%) contrast(150%); /* Safari 6.0 - 9.0 */
				//filter: grayscale(100%) brightness(70%) contrast(150%);
				border: dotted #3576ff 2px;
			}
		}

		.button {
			pointer-events: all;
			position: relative;
			width: 135px;
			height: 40px;
			display: block;
			cursor: pointer;
			margin: 0 auto;
			margin-top: 30px;
		//	background-color: #3576ff;

			svg {
				position: absolute;
				top: 0;
				left: 0;
			}

			p {
				position: absolute;
				font-size: 18px;
				z-index: 2;
				top: 9px;
				left: 15px;
				margin-bottom: 0;
			}
		}

		.button:hover {
			svg {
				opacity: 0.8;
			}
		}

		.or {
			opacity: 0.3;
			font-size: 16px;
			margin-bottom: 20px;
			margin-top: 20px;
		}

		.useKeyboard {
			font-size: 16px;
			text-decoration: underline;
			pointer-events: all;
			cursor: pointer;
		}
	}
}
