#GameHUD {
	position: absolute;
	width: 100%;
	margin: 0;
	place-items: center center;
	font-size: 12px;
	pointer-events: none;
	text-align: center;
	font-weight: 400;
	z-index: 30;
	visibility: hidden;
	bottom: 18px;
	//	pointer-events: none;

	.gameInfo {
		visibility: hidden;
		position: relative;
		width: 723px;

		margin-left: auto;
		margin-right: auto;

		height: 37px;
		background-image: url('/assets/images/hud/lines.png');
		background-size: 723px 37px;
		background-repeat: no-repeat;
		display: flex;

		@media only screen and (max-width: 723px) {
			width: 344px;
			background-image: url('/assets/images/hud/linesMobile.png');
			background-size: 344px 28px;
			height: 60px;
		}

		.level {
			top: 17px;
			display: inline-block;
			position: relative;
			left: 42px;

			.levelCount {
				@media only screen and (max-width: 723px) {
					top: 20px;
					position: absolute;
					left: -15px;
				}
			}
		}

		.gifts {
			display: block;
			top: 17px;
			position: relative;
			left: 100px;

			@media only screen and (max-width: 723px) {
				position: absolute;
				left: calc(100% - 50px);

			}

			.giftCount {
				text-align: right;
				width: 40px;

				@media only screen and (max-width: 723px) {
					top: 20px;
					position: absolute;
					left: -37px;
				}
			}

			img {
				margin-top: -1px;
				margin-right: -10px;
				width: 16px;
				height: 16px;


			}
		}

		.health {
			display: inline-block;
			top: 17px;
			position: absolute;
			left: 495px;

			@media only screen and (max-width: 723px) {
				left: calc(50% - 17px);

			}
		}

		.healthCount {
			margin-top: 5px;
			display: flex;

			@media only screen and (max-width: 723px) {
				top: 23px;
				position: absolute;
				left: -60px;
			}

			.healthItem {
				background-color: rgba(255, 255, 255, 1);
				width: 25px;
				height: 5px;

				margin-right: 10px;
				//	transform: rotate(45deg);
			}


		}

		.header {
			display: flex;

			.resultHeader {
				margin-left: 15px;
				font-size: 14px;
			}
		}
	}

	#videoArea {
		visibility: hidden;
		position: absolute;
		left: calc(50% - 100px);
		z-index: 20;
		bottom: 5px;
		width: 200px;
		height: 150px;
		border: solid 1px rgba(255, 255, 255, 0.5);
		overflow: hidden;

		#_webcam {
			//	display:none;

			position: absolute;
			//	mix-blend-mode: luminosity;
			-webkit-transform: scaleX(-1.25) scaleY(1.25);
			transform: scaleX(-1.25) scaleY(1.25);
			//	opacity: 0.3;
			//	display: none;
			//	border-top-left-radius: 5px;
			//	border-top-right-radius: 5px;
			left: 20px;
			top: 15px;
			-webkit-filter: grayscale(100%) brightness(70%) contrast(150%); /* Safari 6.0 - 9.0 */
			filter: grayscale(100%) brightness(70%) contrast(150%);
		}

		#_canvasOriginal {
			//	border-top-left-radius: 5px;
			//	border-top-right-radius: 5px;
			//		border-radius: 5px;
			left: 20px;
			top: 15px;
			-webkit-transform: scaleX(-1.25) scaleY(1.25);
			transform: scaleX(-1.25) scaleY(1.25);
			//	image-rendering: pixelated;
			//	mix-blend-mode: luminosity;
			position: absolute;
			background-color: transparent;
		}

		#_canvas {
			left: 20px;
			-webkit-transform: scaleX(-5) scaleY(5);
			transform: scaleX(-5) scaleY(5);
			image-rendering: pixelated;
			//mix-blend-mode: overlay;
			display: none;
		}

		.hat {
			position: absolute;
		}

		.placeHandsHere {
			position: absolute;
			//	top: -13px;

			.leftHand {
				position: absolute;

				.boxLeft {
					position: absolute;
					width: 101px;
					height: 148px;
					//	left: -20px;
					/*	border-top-left-radius: 20%;
						border-top-right-radius: 40%;
						border-bottom-left-radius: 30%;
						border-bottom-right-radius: 10%;*/
					border: #64f3a7 1px dashed;
					background-color: rgba(100, 243, 167, 0.1);

					svg {
						position: absolute;
						top: 50px;
						left: 30px;
						width: 37px;

					}
				}

				.handText {
					position: absolute;
					left: -75px;
					top: 120px;
					font-size: 16px;
					color: #ffffff;
					width: 250px;
				}
			}

			.rightHand {
				position: absolute;

				.boxRight {
					position: absolute;
					left: 100px;
					width: 98px;
					height: 148px;
					/*border-top-left-radius: 20%;
					border-top-right-radius: 40%;
					border-bottom-left-radius: 30%;
					border-bottom-right-radius: 10%;*/
					border: #64f3a7 1px dashed;
					background-color: rgba(100, 243, 167, 0.1);

					svg {
						position: absolute;
						top: 50px;
						left: 30px;
						width: 37px;
					}
				}

				.handText {
					position: absolute;
					left: -25px;
					top: 120px;
					font-size: 16px;
					color: #ffffff;
					width: 350px;
				}
			}
		}
	}

	.keyboardControls {
		position: absolute;
		left: calc(50% - 90px);
		z-index: 20;
		bottom: 70px;
		visibility: hidden;

		.keyLeft {
			display: inline-block;
			width: 56px;
			height: 56px;
			background-color: rgba(255, 255, 255, 0.1);
			border-radius: 4px;
			margin-right: 5px;

			svg {
				margin-top: 20px;
			}
		}

		.keyUp {
			position: relative;
			display: inline-block;
			width: 56px;
			height: 56px;
			background-color: rgba(255, 255, 255, 0.1);
			border-radius: 4px;
			bottom: 66px;
			margin-right: 5px;

			svg {
				transform: rotate(90deg);
				margin-top: 17px;
			}
		}

		.keyRight {
			display: inline-block;
			width: 56px;
			height: 56px;
			background-color: rgba(255, 255, 255, 0.1);
			border-radius: 4px;

			svg {
				margin-top: 20px;

				transform: rotate(180deg);
			}
		}
	}

	#rotationAngle {
		visibility: hidden;
		position: absolute;
		bottom: 200px;
		width: 120px;
		height: 120px;
		left: calc(50% - 60px);
		z-index: 40;
		opacity: 0;
		//background-color: red;

		.rotationText {
			font-size: 14px;
			//color: rgba(255, 255, 255, 0.6);
			width: 120px;
			text-align: center;
			margin-top: 70px;
			color: #ffffff;
			opacity: 0.6;
			margin-left: 2px;
		}

		.steeringLineTop {
			position: absolute;
			top: 98px;
			left: -80px;
			width: 280px;
			height: 2px;
			border: 1px solid #ffffff;
			background-color: #ffffff;
			opacity: 0.6;
			/*	border-radius: 50%;

				border-bottom-color: transparent;
				border-left-color: transparent;
	*/
			//transform: rotate(135deg);
			//	opacity: 1;

			.endLineLeft {
				position: absolute;
				background-color: #ffffff;
				height: 10px;
				width: 2px;
				bottom: -5px;
				left: -2px;
			}

			.endLineRight {
				position: absolute;
				background-color: #ffffff;
				height: 10px;
				width: 2px;
				margin-left: 278px;
				bottom: -5px;
			}
		}

		.circleInner {
			position: absolute;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #ffffff;
			border: 2px solid #ffffff;
			margin-left: 55px;
			top: 94px;
		}

		.lineToCenter {
			width: 1px;
			height: 110px;
			background-color: #ffffff;
			top: 100px;
			left: 60px;
			position: absolute;
			opacity: 0.6;
			//	-webkit-transform: scaleY(0);
			//	transform: scaleY(0);
		}
	}

	.steeringAxis {
		visibility: hidden;
		left: calc(50% - 150px / 2);
		position: absolute;
		z-index: 70;
		bottom: 154px;
		width: 150px;
		opacity: 0;

		.circleInner {
			position: absolute;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #ffffff;
			border: 2px solid #ffffff;
			margin-left: calc(150px / 2 - 10px / 2);
			top: -4px;
		}

		.innerSteeringAxis {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 150px;
			height: 2px;
			background-color: #ffffff;
		}
	}

	@media (min-width: 723px) {
		.keyboardControls {
			bottom: 0;
		}
	}
}
