#TryAgainScreen {
	//pointer-events: none;
	display: none;
	opacity: 0;
	position: absolute;
	z-index: 400;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.85); //rgba(7, 7, 66, 0.9);

	.gfx {
		margin-bottom: 30px;
	}

	.centerContent {
		position: absolute;

		height: 400px;
		margin: 0;

		top: calc(100% / 2 - 125px);
		width: 100%;

		font-size: 27px;
		text-align: center;

		.headline {
			display: block;
			color: #ffffff;
			font-size: 70px;
			font-weight: normal;
			font-style: normal;
			max-width: 600px;
			margin: 0 auto 55px auto;
		}

		.startAgain {
			font-weight: 500;
			//opacity: 0.5;
			font-size: 26px;
			//line-height: 22px;
			margin-bottom: 50px;
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
			width: auto;
			text-decoration: underline;
			cursor: pointer;
			pointer-events: all;
		}
	}

	.button {
		position: relative;
		width: 105px;
		height: 40px;
		display: block;
		cursor: pointer;
		margin: 0 auto;

		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}

		p {
			position: absolute;
			font-size: 18px;
			z-index: 2;
			top: 9px;
			left: 20px;
			margin-bottom: 0;
		}
	}

	.button:hover {
		svg {
			opacity: 0.8;
		}
	}
}
